.fc-timegrid-slot {
    height: 45px!important;
}

.fc-timegrid-slot-lane {
    height: 45px!important;
}
.fc-popover-body{
    max-height: 400px!important;
    overflow-y: auto!important;
    z-index: 40!important;
}
.fc-icon{
  color: white!important;
}
.fc-icon-x{
    transition: linear .2s;
    color: rgba(0, 0, 0, 0.9) !important;
}
.fc-icon-x:hover{
    transition: linear .2s;
    color: rgba(0, 0, 0, 0.5) !important;
}
.fc-more-popover-misc{
    display: none;
}
.invisible_popover {
    display: none;
}
.fc-popover{
    z-index: 10!important;
}
.fc-event-main {
    overflow: auto!important;
}
.fc-event-main::-webkit-scrollbar{
    width: 0;
}

.events-products-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 12px;
}

.events-products-item {
    padding: 0 8px;
    border-radius: 6px;
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.92);
    color: rgba(0, 0, 0, 0.8);
}
.calendar-event{
    padding: 2px 6px;
    overflow: hidden;

    &-top {
        display: flex;
        align-items: center;

        svg {
            font-size: 14px;
        }

        span {
            font-size: 13px;
            font-weight: 600;
            color: white;
        }
    }

    &-title {
        font-weight: 600;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    &-description {
        margin:3px 0 0 0;
        color: white;
        word-break: break-all;
        white-space: pre-wrap;
        font-size: 12px;
    }
}