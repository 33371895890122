.css-ewzl60-MuiButtonBase-root-MuiButton-root{
  background-color: #546e7a!important;
  transition: linear .2s!important;

  &:hover{
    opacity: .8;
    transition: linear .2s;
  }
}

.product-wrap {
  &::-webkit-scrollbar {
    height: 6px;
    background-color: #e5e5e5;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-color: rgb(255, 172, 0);
    border-radius: 3px;
  }
}

.driver-menu-card {
  background-color: #f5f4f4;
  border-radius: 10px;
  padding: 6px 14px;
  margin: 16px;

  .cameraButton input {
  display: none;
}
}

.driver-menu {

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 18px;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
    }

    svg {
      background-color: #a6a6a6;
      width: 28px;
      height: 28px;
      cursor: pointer;
      border-radius: 6px;
      transition: linear .2s;

      &:hover path {
        fill: rgba(0, 0, 0, 0.6);
      }

      path {
        transition: linear .2s;
        fill: white;
      }
    }
  }

  &-products {
    display: flex;
    align-items: center;
    font-weight: 600;

    &-wrap {
      display: flex;
      align-items: center;
    }

    &-item {
      margin-left: 8px;
      border-radius: 10px;
      padding: 2px 6px;
      font-size: 15px;
      font-weight: bold;
      background-color: #dadada;
    }
  }

  &-comment {
    span {
      font-size: 15px;
      font-weight: 600;
    }

    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }
  }

  &-client {
    span {
      font-size: 15px;
      font-weight: 600;
    }

    div {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }

    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }
  }

  &-input {
    padding: 12px;

    input {
      min-width: 200px;
    }

    textarea {
      min-width: 235px;
    }

    &-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin-left: 6px;
      }
    }
  }

  &-upload {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 16px;
    flex-wrap: wrap;

    &-wrap {
      position: relative;

      img {
        height: 70px;
        width: 130px;
        border-radius: 12px;
        object-fit: cover;
      }

      svg {
        position: absolute;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, 0.6);
        top: 4px;
        cursor: pointer;
        right: 4px;

        &:hover path {
          transition: linear .2s;
          fill: #050505;
        }

        path {
          transition: linear .2s;
          fill: #a6a6a6;
        }
      }
    }
  }
  &-files {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    &-wrap {
      position: relative;

      img {
        height: 76px;
        width: 141px;
        cursor: pointer;
        border-radius: 12px;
        object-fit: cover;
      }

      svg {
        position: absolute;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, 0.6);
        top: 4px;
        cursor: pointer;
        right: 4px;

        &:hover path {
          transition: linear .2s;
          fill: #050505;
        }

        path {
          transition: linear .2s;
          fill: #484848;
        }
      }
    }
  }

  &-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
      color: #eeeff2;
      &:hover{
        color: black;
        background: transparent!important;
      }
    }
  }

  &-button-inWork{
    button{
      color: #eeeff2;
      &:hover{
        color: black;
        background: transparent!important;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .css-1no8b9u-MuiCardHeader-root {
    width: 89vw;
  }
}

