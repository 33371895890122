

//statistics

.charts-container {
  padding: 0 25px;
}

.charts-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.charts-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 0;
  padding: 25px 0;
}

.charts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
}

.charts {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
  margin-top: 16px;

  &canvas {

  }
}

.overflow-canvas {
  cursor: pointer;
}

.charts-date {
  padding: 5px 8px;
  display: flex;
  align-items: center;

  & h4 {
    margin: 0 8px 0;
  }
}

.charts-pie-canvas {
  margin: 8px 0;
  padding: 0 8px;
  cursor: pointer;
}

.charts-doughnut-canvas {
  margin: 8px 0;
  cursor: pointer;
}

.charts-pie {
  width: 380px;
  //height: 450px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  canvas {
  }
}

.overflow-canvas {
  overflow-x: auto;
  padding: 10px;
  margin: 6px;
}
.overflow-canvas-show-menu {
  overflow-x: auto;
  padding: 10px;
  margin: 6px;
}

@media (min-width: 992px) and (max-width: 1499px) {
  .overflow-canvas {
    width: calc(100vw - 355px);
  }

}
@media (min-width: 992px) and (max-width: 1310px) {
  .overflow-canvas-show-menu {
    width: calc(100vw - 175px);
  }

}

@media (min-width: 767px) and (max-width: 992px) {
  .overflow-canvas {
    width: calc(100vw - 165px);
  }
  .overflow-canvas-show-menu {
    width: calc(100vw - 185px);
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .overflow-canvas {
    width: calc(100vw - 120px);
  }
  .overflow-canvas-show-menu {
    width: calc(100vw - 120px);
  }
}
@media (min-width: 0) and (max-width: 400px) {
  .overflow-canvas {
    width: calc(100vw - 130px);
  }
  .overflow-canvas-show-menu {
    width: calc(100vw - 130px);
  }
}

.charts-doughnut {
  width: 380px;

  &-bottom {
    display: flex;
    justify-content: center;
    margin: 4px;
    flex-wrap: wrap;

    &-info {
      padding: 3px 4px;
      margin: 3px;
      text-align: center;
      font-size: 14px;
      border-radius: 6px;
    }
  }

  //&-percent{
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  padding: 10px;
  //  border-radius: 50%;
  //  height: 90px;
  //  width: 90px;
  //  font-size: 26px;
  //  position: absolute;
  //  top: 45%;
  //  right: 38%;
  //  background-color: #bbbbbb;
  //}
}

.charts-totalsum {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.charts-pie-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  padding: 0 22px 12px;

  &-head {
    display: flex;
    justify-content: space-between;

  }

  & span {
    text-align: center;
    font-weight: 400;
  }

  & h4 {
    font-size: 15px;
    margin: 0;
    font-weight: 500;
    text-align: center;
    width: 90px;
  }
}

.css-1kykfr {
  height: 100%;
}

.css-vje9uk {
  margin: 6px;
}

.css-1v994a0 {
  text-transform: uppercase;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px;
}

.css-12muqx8-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: 700;
}

.loader-charts-wrap {
  display: flex;
  height: 485px;
  justify-content: center;
  align-items: center;
}
.loader-charts-wrap-bottom {

  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width:0px) and (max-width: 350px) {
  .css-1xu9kt2-MuiButtonBase-root-MuiPaginationItem-root {
    min-width: 16px !important;
    height: 20px !important;
    font-size: 13px!important;
  }
}

@media (min-width:350px) and (max-width: 400px) {
  .css-1xu9kt2-MuiButtonBase-root-MuiPaginationItem-root {
    min-width: 21px !important;
    height: 21px !important;
  }
}
@media(min-width: 400px) and (max-width: 447px) {
  .css-1xu9kt2-MuiButtonBase-root-MuiPaginationItem-root {
    min-width: 24px !important;
    height: 24px !important;
  }
}

@media screen and (max-width: 447px) {

  .charts-title {
    font-size: 15px;
    padding: 18px 0;
  }
  .charts-pie-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 12px;

    //  &-head {
    //    display: flex;
    //    justify-content: space-between;
    //}
    & span {
      font-size: 13px;
    }

    & h4 {
      font-size: 12px;
    }
  }
  .charts-totalsum {
    font-size: 15px;
  }
}

@media (min-width: 800px) and (max-width: 1100px) {
  .charts-pie {
    width: 320px;
  }
  .charts-doughnut {
    width: 320px;
  }
}

@media (min-width: 0) and (max-width: 350px) {
  .charts-pie {
    width: 230px;
  }
  .charts-doughnut {
    width: 230px;
  }
}

@media (min-width: 700px) and (max-width: 800px) {
  .charts-pie {
    width: 310px;
  }
  .charts-doughnut {
    width: 310px;
  }
}

@media (min-width: 600px) and (max-width: 720px) {
  .charts-pie {
    width: 280px;
  }
  .charts-doughnut {
    width: 280px;
  }
}

@media screen and (max-width: 650px) {
  .charts-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .charts-pie {
    width: 420px;
  }
  .charts-doughnut {
    width: 420px;
  }
}

@media (min-width: 0) and (max-width: 517px) {
  .charts-top {
    display: flex;

    & .charts {
      margin: 16px;
    }
  }
}

@media screen and (max-width: 960px) {
  .charts-header {
    flex-direction: column;
  }
}

@media screen and (max-width: 515px) {

  .charts-pie {
    width: 65vw;
  }
  .charts-doughnut {
    width: 65vw;
  }
}
@media screen and (max-width: 470px) {
  .charts-date-wrap {
    flex-direction: column;
  }
}
