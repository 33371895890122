.content-box-notification {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 12%), 0px 8px 10px -3px rgb(0 0 0 / 14%), 0px 5px 5px -3px rgb(0 0 0 / 12%);
  border-radius: 8px;
  margin-top: 10px;
  max-width: 210px;
  word-wrap: break-word;
  margin-bottom: 8px;
  padding: 3px 8px;
}

.notification-btn{
  transition: ease-in-out .2s;
  text-align: center;
  padding: 6px 14px;
  font-size: 14px;
  height: 33px;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #eeeff2;
  cursor: pointer;
  &:hover{
    transition: ease-in-out .2s;
    border: 1px solid #a6a6a6;
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .notification-link {
    justify-content: start!important;
    margin-left: 0!important;
  }
}
.notification-link {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
  color: #546e7a;
  text-decoration: none;
  width: 100px;
  margin-left: 16px;

  & p{
    font-size: 18px;
  }

  &:hover {
    opacity: .8;
  }

  &:hover .notification-chevron {
    transform: translateX(-6px);
    transition: linear .2s;
  }

  .notification-chevron {
    transition: linear .2s;
    width: 28px;
    height: 28px;
  }
}