.dadata_input{
  min-height: 56px;
  border-radius: 8px;
  width: 100%;
  padding:16.5px 14px;
  font-size: 1rem;
  outline: none!important;

  border: solid 1px #b2b2b2;
  &:hover{
    border: solid 1px #546e7a;

  }

  &:focus{
    border: solid 2px #546e7a!important;
  }

}
