span {
  margin-right: 6px;
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.5);
}

.show-record{
  &-wrap {
    display: inline-flex;
  }
  &-text{
    margin-right: 6px;
    color: rgba(0, 0, 0, 0.5);
  }
  &-not-data{
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
  }
}
